import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Tab, Nav } from "react-bootstrap"
import "aos/dist/aos.css"
import india from "../images/india.png"
import regionsDot from "../images/regionsdot.png"
import Slider from "react-slick"

// Ahmedabad
import ahmSec1 from "../images/regions/ahm/sec1.png"
import ahmSec2 from "../images/regions/ahm/sec2.png"
import ahmSoc1 from "../images/regions/ahm/soc1.png"
import ahmSoc2 from "../images/regions/ahm/soc2.png"

// Bengaluru
import benSec1 from "../images/regions/ben/sec1.png"
import benSec2 from "../images/regions/ben/sec2.png"
import benSec3 from "../images/regions/ben/sec3.png"
import benSoc1 from "../images/regions/ben/soc1.png"
import benSoc2 from "../images/regions/ben/soc2.png"

// Chennai
import cheSec1 from "../images/regions/che/sec1.png"
import cheSec2 from "../images/regions/che/sec2.png"
import cheSec3 from "../images/regions/che/sec3.png"
import cheSoc1 from "../images/regions/che/soc1.png"
import cheSoc2 from "../images/regions/che/soc2.png"
import cheSoc3 from "../images/regions/che/soc3.png"

// Delhi
import delSec1 from "../images/regions/del/sec1.png"
import delSec2 from "../images/regions/del/sec2.png"
import delSec3 from "../images/regions/del/sec3.png"
import delSoc1 from "../images/regions/del/soc1.png"
import delSoc2 from "../images/regions/del/soc2.png"

// Hyderabad
import hydSec1 from "../images/regions/hyd/sec1.png"
import hydSec2 from "../images/regions/hyd/sec2.png"
import hydSec3 from "../images/regions/hyd/sec3.png"
import hydSoc1 from "../images/regions/hyd/soc1.png"
import hydSoc2 from "../images/regions/hyd/soc2.png"

// Mumbai
import mumSec1 from "../images/regions/mum/sec1.png"
import mumSec2 from "../images/regions/mum/sec2.png"
import mumSec3 from "../images/regions/mum/sec3.png"
import mumSoc1 from "../images/regions/mum/soc1.png"
import mumSoc2 from "../images/regions/mum/soc2.png"

// Pune
import punSec1 from "../images/regions/pun/sec1.png"
import punSec2 from "../images/regions/pun/sec2.png"
import punSec3 from "../images/regions/pun/sec3.png"
import punSoc1 from "../images/regions/pun/soc1.png"
import punSoc2 from "../images/regions/pun/soc2.png"
import punSoc3 from "../images/regions/pun/soc3.png"

const Regions = () => {
  const [key, setKey] = useState("first")
  const [tabSelect, setTabSelect] = useState("first")

  const selectChange = event => {
    setTabSelect(event.target.value)
    setKey(event.target.value)
  }

  const settingsSoc = {
    dots: true,
    dotsClass: "slick-dots fel-indi laf-fel-indi",
    infinite: true,
    fade: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  }
  return (
    <Layout pageName="Regions">
      <SEO title="Regions" />
      <div className="ban-bg-black py-5">
        <Container>
          <Row>
            <div className="col-lg-4 pr-lg-0">
              <p className="ed-title white text-center text-lg-left">
                Things to know
              </p>
              <p className="jtf-head white max-sm-70 mx-auto mb-3 mb-lg-0 text-center text-lg-left">
                Where do they teach?
              </p>
              <div className="citylist pt-lg-5 d-none d-lg-block">
                <p className="cdl cdl-ahmedabad">Ahmedabad</p>
                <p className="cdl cdl-bengaluru">Bengaluru</p>
                <p className="cdl cdl-chennai">Chennai</p>
                <p className="cdl cdl-delhi">Delhi</p>
                <p className="cdl cdl-kolkata">Kolkata</p>
                <p className="cdl cdl-hyderabad">Hyderabad</p>
                <p className="cdl cdl-mumbai">Mumbai</p>
                <p className="cdl cdl-pune">Pune</p>
              </div>
              <p className="fi-txt white pt-lg-4 pr-lg-20px pr-sm-0">
                We work across the country and our impact can be felt most
                strongly in the communities in which we work. Every city we work
                in has a unique story and offers a distinct opportunity for our
                Fellows, Alumni and Staff to work alongside local partners,
                connect with a community, and help children achieve to their
                fullest potential.
              </p>
            </div>
            <div className="col-lg-8 d-none d-lg-block">
              <div className="indiaMap">
                <img src={india} className="w-100" />
                <div className="city delhi">
                  <div className="circle"></div>
                  <div className="details text-center">
                    <div className="cd-cont">
                      <p className="cd-title">Year 2021</p>
                      <p className="cd-head">
                        260 Fellows
                        <br />
                        8300 Students
                        <br />
                        952 Alumni
                      </p>
                    </div>
                  </div>
                </div>
                <div className="city ahmedabad">
                  <div className="circle"></div>
                  <div className="details text-center">
                    <div className="cd-cont">
                      <p className="cd-title">Year 2021</p>
                      <p className="cd-head">
                        47 Fellows
                        <br />
                        1800 Students
                        <br />
                        180 Alumni
                      </p>
                    </div>
                  </div>
                </div>
                <div className="city bengaluru">
                  <div className="circle"></div>
                  <div className="details text-center">
                    <div className="cd-cont">
                      <p className="cd-title">Year 2021</p>
                      <p className="cd-head">
                        120 Fellows
                        <br />
                        3,000 Students
                        <br />
                        222 Alumni
                      </p>
                    </div>
                  </div>
                </div>
                <div className="city chennai">
                  <div className="circle"></div>
                  <div className="details text-center">
                    <div className="cd-cont">
                      <p className="cd-title">Year 2021</p>
                      <p className="cd-head">
                        110 Fellows
                        <br />
                        3300 Students
                        <br />
                        365 Alumni
                      </p>
                    </div>
                  </div>
                </div>
                <div className="city hyderabad">
                  <div className="circle"></div>
                  <div className="details text-center">
                    <div className="cd-cont">
                      <p className="cd-title">Year 2021</p>
                      <p className="cd-head">
                        87 Fellows
                        <br />
                        3600 Students
                        <br />
                        422 Alumni
                      </p>
                    </div>
                  </div>
                </div>
                <div className="city kolkata">
                  <div className="circle"></div>
                  <div className="details text-center">
                    <div className="cd-cont">
                      <p className="cd-title">Year 2022</p>
                      <p className="cd-head">Coming Soon!</p>
                    </div>
                  </div>
                </div>
                <div className="city mumbai">
                  <div className="circle"></div>
                  <div className="details text-center">
                    <div className="cd-cont">
                      <p className="cd-title">Year 2021</p>
                      <p className="cd-head">
                        144 Fellows
                        <br />
                        4000 Students
                        <br />
                        817 Alumni
                      </p>
                    </div>
                  </div>
                </div>
                <div className="city pune">
                  <div className="circle"></div>
                  <div className="details text-center">
                    <div className="cd-cont">
                      <p className="cd-title">Year 2021</p>
                      <p className="cd-head">
                        145 Fellows
                        <br />
                        7500 Students
                        <br />
                        854 Alumni
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>

      <Tab.Container
        id="left-tabs-example"
        activeKey={key}
        onSelect={k => setKey(k)}
      >
        <div className="tabContaier d-none d-lg-block">
          <div className="container px-0">
            <Nav className="regions-tab py-lg-4">
              <Nav.Item>
                <Nav.Link eventKey="first" className="ahmedabad">
                  Ahmedabad
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second" className="bengaluru">
                  Bengaluru
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="third" className="chennai">
                  Chennai
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="fourth" className="delhi">
                  Delhi
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="fifth" className="hyderabad">
                  Hyderabad
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="sixth" className="mumbai">
                  Mumbai
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="seventh" className="pune">
                  Pune
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
        <div className="container py-3 d-block d-lg-none">
          <select
            value={tabSelect}
            className="region-select"
            onChange={selectChange}
          >
            <option value="first">Ahmedabad</option>
            <option value="second">Bengaluru</option>
            <option value="third">Chennai</option>
            <option value="fourth">Delhi</option>
            <option value="fifth">Hyderabad</option>
            <option value="sixth">Mumbai</option>
            <option value="seventh">Pune</option>
          </select>
        </div>
        <Tab.Content className=" dd-none dd-lg-block">
          <Tab.Pane eventKey="first" className="region ahmedabad">
            <div className="bg-sec posrel">
              <img src={regionsDot} className="regPatch" />
              <Container>
                <p className="fi-head text-center">The Journey</p>

                <div className="timeline">
                  <div className="tl-item pb-5">
                    <div className="year">2021</div>
                    <div className="content">
                      <p className="ed-title bolder">
                        47 Fellows
                        <br />
                        1800 Students
                        <br />
                        180 Alumni
                        <br />
                      </p>
                    </div>
                  </div>
                  <div className="tl-item pb-5">
                    <div className="year">2019</div>
                    <div className="content">
                      <p className="fi-txt">
                        <span className="bolder">
                          Students make it to Rome for the ICAN Conference
                        </span>
                        <br />
                        Two of our Students were invited to present their
                        project at the International ICAN conference in Rome.
                        Their project involves running community centers run by
                        and for Students in the community.
                      </p>
                    </div>
                  </div>
                  <div className="tl-item pb-5">
                    <div className="year">2016</div>
                    <div className="content">
                      <p className="fi-txt">
                        <span className="bolder">
                          20 schools, 80 Fellows, and 2000 Students on the path.
                        </span>
                        <br />
                        We saw our elementary Students outperform on every
                        single metric when it came to the mastery of basic
                        operations and reading. We were above the state and city
                        averages for both.
                      </p>
                    </div>
                  </div>
                  <div className="tl-item pb-5">
                    <div className="year">2015</div>
                    <div className="content">
                      <p className="fi-txt">
                        <span className="bolder">
                          Parent Engagement and Annual Days.
                        </span>
                        <br />
                        Fellows build partnerships with parents towards their
                        child’s development. Project Parvarish started to build
                        a safe learning environment at home. The majority of our
                        schools had their first ever annual day celebration.
                      </p>
                    </div>
                  </div>
                  <div className="tl-item pb-5">
                    <div className="year">2014</div>
                    <div className="content">
                      <p className="fi-txt">
                        <span className="bolder">Ahmedabad’s first cohort</span>
                        <br />
                        With 34 Fellows & 1100 Students
                      </p>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
            <Container className="py-lg-5">
              <p className="wn-title mt-0 text-center pb-lg-5 mb-3 mb-lg-0">
                What Enabled Change{" "}
              </p>
              <Row className="py-lg-3 mx-0">
                <div className="col-lg-6">
                  <img src={ahmSec1} className="w-100" />
                </div>
                <div className="col-lg-6 pl-lg-5 align-center">
                  <div>
                    <p className="fin-title mt-3 mb-1 mt-lg-0 mb-lg-4 bolder">
                      Relationships and Collaboration
                    </p>
                    <p className="fi-txt mb-3 mb-lg-0">
                      The relationships that we have built with our Students,
                      their parents, our school leaders, our community members,
                      and between Fellows and staff of Teach For India have been
                      instrumental in almost everything that we have achieved.
                      Culture of collaboration is the bedrock of all the success
                      we have achieved in the region.
                    </p>
                  </div>
                </div>
              </Row>
              <Row className="py-lg-3 mx-0">
                <div className="col-lg-6">
                  <img src={ahmSec2} className="w-100" />
                </div>
                <div className="col-lg-6 pl-lg-5 align-center">
                  <div>
                    <p className="fin-title mt-3 mb-1 mt-lg-0 mb-lg-4 bolder">
                      Keeping children at the center
                    </p>
                    <p className="fi-txt mb-3 mb-lg-0">
                      In every decision we take and every plan we make, we have
                      kept our children at the center. It has been our north
                      star in deciding strategy and priority. Building
                      partnership with our Students to ensure that their voices
                      are taken into consideration when decisions impacting them
                      are taken.
                    </p>
                  </div>
                </div>
              </Row>
            </Container>
            <Container className="py-lg-5 px-3 px-lg-0">
              <Slider {...settingsSoc} className="laf-fel regions">
                <div className="fel-car pt-3 py-lg-5">
                  <p className="fi-head sp-abs">Stories of Impact</p>
                  <div className="fel-cont">
                    <p className="fel-car-title">Samait Shala </p>
                    <p className="fel-car-txt">
                      Samait Shala was started by Kushal Dattani who is a 2014
                      Ahmedabad Fellow. They work with teachers directly to
                      ensure that all classrooms in all schools are inclusive of
                      students who learn in different ways and at different
                      paces and focuses on training teachers to use pedagogy
                      which will help them ensure that no child in their
                      classroom gets left behind.
                    </p>
                  </div>
                  <div className="fel-img-cont">
                    <img src={ahmSoc1} className="w-100 " />
                  </div>
                </div>
                <div className="fel-car pt-3 py-lg-5">
                  <p className="fi-head sp-abs">Stories of Impact</p>
                  <div className="fel-cont">
                    <p className="fel-car-title">
                      Harnessing the power of technology
                    </p>
                    <p className="fel-car-txt">
                      Irfan Lalani, our 2014 Alumnus realised while teaching his
                      Grade 3 Students that the state prescribed Computer
                      Science curriculum was below par. And so he took it upon
                      himself to teach them what he thought they ought to learn.
                      Code To Enhance Learning leverages coding as a tool to
                      build critical thinking, creativity, collaboration and
                      perseverance in children from grades 4-9. They have
                      reached 2500+ children till now.
                    </p>
                  </div>
                  <div className="fel-img-cont">
                    <img src={ahmSoc2} className="w-100" />
                  </div>
                </div>
              </Slider>
            </Container>
          </Tab.Pane>
          <Tab.Pane eventKey="second" className="region bengaluru">
            <div className="bg-sec posrel">
              <img src={regionsDot} className="regPatch" />
              <Container>
                <p className="fi-head text-center">The Journey</p>

                <div className="timeline">
                  <div className="tl-item pb-5">
                    <div className="year">2021</div>
                    <div className="content">
                      <p className="ed-title bolder">
                        120 Fellows
                        <br /> 3,000 Students
                        <br /> 222 Alumni
                      </p>
                    </div>
                  </div>
                  <div className="tl-item pb-5">
                    <div className="year">2018</div>
                    <div className="content">
                      <p className="fi-txt">
                        <span className="bolder">
                          Alumni innovations mushroom
                        </span>
                        <br />
                        Alumni start 7 education enterprises, enter system roles
                        like Assistant School Leaders, Cluster Transformation
                        Leaders
                      </p>
                    </div>
                  </div>
                  <div className="tl-item pb-5">
                    <div className="year">2017</div>
                    <div className="content">
                      <p className="fi-txt">
                        <span className="bolder">
                          Student revolutionaries create community solutions
                        </span>
                        <br />
                        Student projects move towards enrolling out-of-school
                        children, solving community challenges.
                      </p>
                    </div>
                  </div>

                  <div className="tl-item pb-5">
                    <div className="year">2016</div>
                    <div className="content">
                      <p className="fi-txt">
                        <span className="bolder">
                          Community centers emerge to solve contextual
                          challenges
                        </span>
                        <br />
                        Fellows across the city set up community centers to
                        facilitate additional learning time and student-run
                        community interventions.
                      </p>
                    </div>
                  </div>
                  <div className="tl-item pb-5">
                    <div className="year">2015</div>
                    <div className="content">
                      <p className="fi-txt">
                        <span className="bolder">Bengaluru’s first cohort</span>
                        <br />
                        With 57 Fellows & 2182 Students
                      </p>
                    </div>
                  </div>
                  <div className="tl-item pb-5">
                    <div className="year">2009</div>
                    <div className="content">
                      <p className="fi-txt">
                        <span className="bolder">The Fellowship begins</span>
                        <br />
                        in Mumbai & Pune
                      </p>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
            <Container className="py-lg-5">
              <p className="wn-title mt-0 text-center pb-lg-5 mb-3 mb-lg-0">
                What Enabled Change{" "}
              </p>
              <Row className="py-lg-3 mx-0">
                <div className="col-lg-6">
                  <img src={benSec1} className="w-100" />
                </div>
                <div className="col-lg-6 pl-lg-5 align-center">
                  <div>
                    <p className="fin-title mt-3 mb-1 mt-lg-0 mb-lg-4 bolder">
                      A culture of innovation and collaboration
                    </p>
                    <p className="fi-txt mb-3 mb-lg-0">
                      A culture of innovation sits at the heart of the progress
                      we are seeing in Bengaluru. Alumni, Fellows, Staff and
                      partners are leveraging the ripe ecosystem of
                      entrepreneurship in the region to accelerate impact and
                      solve contextual challenges. Teach For India Alumni now
                      lead 7 education enterprises ranging from teacher
                      training, career readiness, early childhood to ed-tech.
                      Platforms like Karnataka Learning Partnership, EkStep and
                      Shikshalokam are fueling collaboration across these
                      various initiatives.
                    </p>
                  </div>
                </div>
              </Row>
              <Row className="py-lg-3 mx-0">
                <div className="col-lg-6">
                  <img src={benSec2} className="w-100" />
                </div>
                <div className="col-lg-6 pl-lg-5 align-center">
                  <div>
                    <p className="fin-title mt-3 mb-1 mt-lg-0 mb-lg-4 bolder">
                      Proof points of strong Student outcomes
                    </p>
                    <p className="fi-txt mb-3 mb-lg-0">
                      Our children are increasingly becoming the leaders we wish
                      to see and are taking up projects that solve for
                      contextual challenges. In the last 3 years, we have gone
                      from 6 sustained Student projects to 23. 24 Student
                      partners have worked with their teacher partners through
                      the year to strengthen their classrooms. We have leveraged
                      multiple partnerships for holistic learning for our
                      children like Reap Benefit, InquiLab, Goldman Sachs MUN,
                      Apple Tech Project.
                    </p>
                  </div>
                </div>
              </Row>
              <Row className="py-lg-3 mx-0">
                <div className="col-lg-6">
                  <img src={benSec3} className="w-100" />
                </div>
                <div className="col-lg-6 pl-lg-5 align-center">
                  <div>
                    <p className="fin-title mt-3 mb-1 mt-lg-0 mb-lg-4 bolder">
                      An Alumni network growing in influence
                    </p>
                    <p className="fi-txt mb-3 mb-lg-0">
                      Teach For India’s Alumni network has grown from 150 to 420
                      in the past 5 years. Our Alumni are now coming together to
                      partner with government bodies, leading organizations
                      ranging from early childhood and career, and to building
                      capacity and teacher training as well as ed-tech
                      platforms. We are now piloting a shared regional pilot
                      with Alumni and other organisations to work towards a
                      common goal in a focused geography.
                    </p>
                  </div>
                </div>
              </Row>
            </Container>
            <Container className="py-lg-5 px-3 px-lg-0">
              <Slider {...settingsSoc} className="laf-fel regions">
                <div className="fel-car pt-3 py-lg-5">
                  <p className="fi-head sp-abs">Stories of Impact</p>
                  <div className="fel-cont">
                    <p className="fel-car-title">Bhargavi, mobilizing change</p>
                    <p className="fel-car-txt">
                      In 2017, Bhargavi Somshekhar started a project to end
                      gender discrimination in her classroom and community.
                      Today, she is working to mobilize children from across
                      Bengaluru to come together and talk about their
                      experiences in the education system with the aim of
                      mobilizing change through the power of their expression.
                    </p>
                  </div>
                  <div className="fel-img-cont">
                    <img src={benSoc1} className="w-100 " />
                  </div>
                </div>
                <div className="fel-car pt-3 py-lg-5">
                  <p className="fi-head sp-abs">Stories of Impact</p>
                  <div className="fel-cont">
                    <p className="fel-car-title">Teacher training with Firki</p>
                    <p className="fel-car-txt">
                      Teach For India has partnered with Mr Narayana Murthy and
                      Government of Karnataka to support teacher training of all
                      state Government teachers through Teach For India’s FIRKI
                      teacher training platform.
                    </p>
                  </div>
                  <div className="fel-img-cont">
                    <img src={benSoc2} className="w-100" />
                  </div>
                </div>
              </Slider>
            </Container>
          </Tab.Pane>
          <Tab.Pane eventKey="third" className="region chennai">
            <div className="bg-sec posrel">
              <img src={regionsDot} className="regPatch" />
              <Container>
                <p className="fi-head text-center">The Journey</p>

                <div className="timeline">
                  <div className="tl-item pb-5">
                    <div className="year">2021</div>
                    <div className="content">
                      <p className="ed-title bolder">
                        110 Fellows
                        <br /> 3300 Students
                        <br /> 365 Alumni
                      </p>
                    </div>
                  </div>

                  <div className="tl-item pb-5">
                    <div className="year">2019</div>
                    <div className="content">
                      <p className="fi-txt">
                        <span className="bolder">
                          First Batch of Grade 10 Students graduate and the
                          beginning of blended learning
                        </span>
                        <br />• 40% of our Students graduate with first class
                        and distinction. <br />• We see a complete shift in our
                        classrooms as they move towards the online format.
                      </p>
                    </div>
                  </div>

                  <div className="tl-item pb-5">
                    <div className="year">2018</div>
                    <div className="content">
                      <p className="fi-txt">
                        <span className="bolder">
                          Partnership with government of Tamil Nadu
                        </span>
                        <br />
                        Our Alumni work as consultants for the Tamil Nadu
                        Education Department in developing the state Education
                        Information Management System and Digital Content
                        Platforms for all teachers and students in the state.
                      </p>
                    </div>
                  </div>

                  <div className="tl-item pb-5">
                    <div className="year">2017</div>
                    <div className="content">
                      <p className="fi-txt">
                        <span className="bolder">
                          Focus on student leadership opportunities
                        </span>
                        <br />
                        Students visit NASA, participate in Young Entrepreneurs
                        Academy and TEDx.
                      </p>
                    </div>
                  </div>
                  <div className="tl-item pb-5">
                    <div className="year">2015</div>
                    <div className="content">
                      <p className="fi-txt">
                        <span className="bolder">Alumni Entrepreneurs</span>
                        <br />
                        We saw our Alumni venture into entrepreneurial journeys
                        that diversified into geographies beyond Chennai.
                      </p>
                    </div>
                  </div>
                  <div className="tl-item pb-5">
                    <div className="year">2012</div>
                    <div className="content">
                      <p className="fi-txt">
                        <span className="bolder">Chennai’s first cohort</span>
                        <br />
                        With 32 Fellows & 660 Students
                      </p>
                    </div>
                  </div>
                  <div className="tl-item pb-5">
                    <div className="year">2009</div>
                    <div className="content">
                      <p className="fi-txt">
                        <span className="bolder">The Fellowship begins</span>
                        <br />
                        in Mumbai & Pune
                      </p>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
            <Container className="py-lg-5">
              <p className="wn-title mt-0 text-center pb-lg-5 mb-3 mb-lg-0">
                What Enabled Change{" "}
              </p>
              <Row className="py-lg-3 mx-0">
                <div className="col-lg-6">
                  <img src={cheSec1} className="w-100" />
                </div>
                <div className="col-lg-6 pl-lg-5 align-center">
                  <div>
                    <p className="fin-title mt-3 mb-1 mt-lg-0 mb-lg-4 bolder">
                      Diversity
                    </p>
                    <p className="fi-txt mb-3 mb-lg-0">
                      The Chennai region today is 300 Alumni strong who have
                      chosen diverse pathways post the Fellowship. Ranging from
                      teachers to teacher trainers, curriculum developers to
                      ed-tech entrepreneurs, NGO representatives to working in
                      government, the diversity of our Alumni is immense. They
                      work in rural, urban and tribal regions, spread across 13
                      districts in the state of Tamil Nadu and bring in
                      perspectives, opinions and voices from many walks of life,
                      enabling us to understand not just educational inequity,
                      but inequity in all aspects of life.
                    </p>
                  </div>
                </div>
              </Row>
              <Row className="py-lg-3 mx-0">
                <div className="col-lg-6">
                  <img src={cheSec2} className="w-100" />
                </div>
                <div className="col-lg-6 pl-lg-5 align-center">
                  <div>
                    <p className="fin-title mt-3 mb-1 mt-lg-0 mb-lg-4 bolder">
                      Public-Private Partnerships
                    </p>
                    <p className="fi-txt mb-3 mb-lg-0">
                      Tamil Nadu has always had a high number of NGOs but the
                      scope of NGOs and government departments working together
                      in a long-term partnership, in the education landscape,
                      has been limited. The last 4-5 years has seen a shift with
                      a significant number of opportunities for civil society to
                      explore long-term partnerships. Today, we have NGOs,
                      private companies, individuals, almost all of which have
                      our Alumni leading change at scale. Public-private
                      partnerships, though in the early stages, have enabled
                      opportunities for our Alumni to explore impact at scale.
                    </p>
                  </div>
                </div>
              </Row>
              <Row className="py-lg-3 mx-0">
                <div className="col-lg-6">
                  <img src={cheSec3} className="w-100" />
                </div>
                <div className="col-lg-6 pl-lg-5 align-center">
                  <div>
                    <p className="fin-title mt-3 mb-1 mt-lg-0 mb-lg-4 bolder">
                      Social Entrepreneurship
                    </p>
                    <p className="fi-txt mb-3 mb-lg-0">
                      In the past 4-5 years, we have seen 7 Alumni organisations
                      take birth in the region. Chennai and Tamil Nadu have a
                      vibrant and unique social entrepreneurship ecosystem that
                      in recent years has grown leaps and bounds. The number of
                      social enterprises in the region is increasing
                      year-on-year, which enables us to take our impact beyond
                      Chennai to other districts in Tamil Nadu and create more
                      opportunities for our Alumni in education and beyond.
                    </p>
                  </div>
                </div>
              </Row>
            </Container>
            <Container className="py-lg-5 px-3 px-lg-0">
              <Slider {...settingsSoc} className="laf-fel regions">
                <div className="fel-car pt-3 py-lg-5">
                  <p className="fi-head sp-abs">Stories of Impact</p>
                  <div className="fel-cont">
                    <p className="fel-car-title">An act of service</p>
                    <p className="fel-car-txt">
                      Srini Swaminathan, a 2010 Alumnus and our former Chennai
                      City Director couldn’t stay idle when migrant workers
                      started going back to their hometowns on foot. He started
                      distributing food packets and was able to serve 135,000
                      labourers. His efforts were recently recognised globally
                      when the United Nations in India and NITI Aayog launched
                      #BharatKeMahaveer, which celebrates Indians who have shown
                      extraordinary kindness in the wake of COVID-19.
                    </p>
                  </div>
                  <div className="fel-img-cont">
                    <img src={cheSoc1} className="w-100 " />
                  </div>
                </div>
                <div className="fel-car pt-3 py-lg-5">
                  <p className="fi-head sp-abs">Stories of Impact</p>
                  <div className="fel-cont">
                    <p className="fel-car-title">Madhi Foundation </p>
                    <p className="fel-car-txt">
                      Madhi Foundation was started in 2015, which works with 15
                      Corporation Schools in Chennai. Started by a Staff Alumna
                      of Teach For India, Merlia Shaukath, today Madhi
                      Foundation is one of the most prominent NGOs that is a
                      trusted partner of the state government of Tamil Nadu,
                      working towards improving Foundational Literacy and
                      Numeracy. Today Madhi has 6 Teach For India Alumni in
                      their core team.
                    </p>
                  </div>
                  <div className="fel-img-cont">
                    <img src={cheSoc2} className="w-100" />
                  </div>
                </div>
                <div className="fel-car pt-3 py-lg-5">
                  <p className="fi-head sp-abs">Stories of Impact</p>
                  <div className="fel-cont">
                    <p className="fel-car-title">Working with collectives</p>
                    <p className="fel-car-txt">
                      Shivaranjani Ramasubramaniyan, an Alumna from the 2016
                      cohort is passionate about working with women&apos;s
                      collectives. She is currently working in rural Cuddalore
                      at Kanavu - an organization she has Co-Founded with 3
                      other Teach For India Alumni.
                    </p>
                  </div>
                  <div className="fel-img-cont">
                    <img src={cheSoc3} className="w-100 " />
                  </div>
                </div>
              </Slider>
            </Container>
          </Tab.Pane>

          <Tab.Pane eventKey="fourth" className="region delhi">
            <div className="bg-sec posrel">
              <img src={regionsDot} className="regPatch" />
              <Container>
                <p className="fi-head text-center">The Journey</p>

                <div className="timeline">
                  <div className="tl-item pb-5">
                    <div className="year">2021</div>
                    <div className="content">
                      <p className="ed-title bolder">
                        260 Fellows
                        <br /> 8300 Students
                        <br /> 952 Alumni
                      </p>
                    </div>
                  </div>

                  <div className="tl-item pb-5">
                    <div className="year">2020</div>
                    <div className="content">
                      <p className="fi-txt">
                        <span className="bolder">
                          Teach For India Delhi Students outperform their peers,
                          and their own odds
                        </span>
                        <br />
                        91% avg. pass% for Teach For India <br />
                        (*59% passed with First Division, 20% with Distinction
                        and 3.3% scored 90%+.)
                        <br />
                        78% avg. pass% for government school Students
                        <br />
                        83% avg. pass% for Delhi Students overall
                      </p>
                    </div>
                  </div>
                  <div className="tl-item pb-5">
                    <div className="year">2015</div>
                    <div className="content">
                      <p className="fi-txt">
                        <span className="bolder">
                          1000+ Student leaders start driving change in the city
                        </span>
                        <br />
                        Jyoti started project Gurukul, inviting Students to
                        conduct workshops in art & spoken word poetry for other
                        Students, hoping to build respect for arts & artists in
                        our communities.
                      </p>
                    </div>
                  </div>

                  <div className="tl-item pb-5">
                    <div className="year">2014</div>
                    <div className="content">
                      <p className="fi-txt">
                        <span className="bolder">
                          30+ Delhi orgs founded by Teach For India Alumni
                        </span>
                        <br />
                        From early childhood education to parent empowerment,
                        Delhi Alumni’s initiatives are starting to fill numerous
                        gaps in the schooling system.
                      </p>
                    </div>
                  </div>

                  <div className="tl-item pb-5">
                    <div className="year">2014</div>
                    <div className="content">
                      <p className="fi-txt">
                        <span className="bolder">
                          Community centres supplement student growth and
                          innovation
                        </span>
                        <br />
                        Fellows across the city set up community centers to
                        facilitate additional learning time and introduce
                        projects and Student-run community interventions.
                      </p>
                    </div>
                  </div>

                  <div className="tl-item pb-5">
                    <div className="year">2011</div>
                    <div className="content">
                      <p className="fi-txt">
                        <span className="bolder">Delhi’s first cohort</span>
                        <br />
                        With 65 Fellows & 2000 Students
                      </p>
                    </div>
                  </div>

                  <div className="tl-item pb-5">
                    <div className="year">2009</div>
                    <div className="content">
                      <p className="fi-txt">
                        <span className="bolder">The Fellowship begins</span>
                        <br /> in Mumbai & Pune
                      </p>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
            <Container className="py-lg-5">
              <p className="wn-title mt-0 text-center pb-lg-5 mb-3 mb-lg-0">
                What Enabled Change{" "}
              </p>
              <Row className="py-lg-3 mx-0">
                <div className="col-lg-6">
                  <img src={delSec1} className="w-100" />
                </div>
                <div className="col-lg-6 pl-lg-5 align-center">
                  <div>
                    <p className="fin-title mt-3 mb-1 mt-lg-0 mb-lg-4 bolder">
                      A culture of ownership & collaboration
                    </p>
                    <p className="fi-txt mb-3 mb-lg-0">
                      Teach For India Delhi staff and Alumni have operated with
                      a lens of high ownership and deep collaboration. The
                      results are evident not only in the number of BTCPs,
                      Community centers, Staff, Fellow and Student-run
                      initiatives in the Fellowship, but also in the number of
                      Alumni entrepreneurial ventures, strategic partnerships
                      and overall connectedness of the Teach For India community
                      in Delhi.
                    </p>
                  </div>
                </div>
              </Row>
              <Row className="py-lg-3 mx-0">
                <div className="col-lg-6">
                  <img src={delSec2} className="w-100" />
                </div>
                <div className="col-lg-6 pl-lg-5 align-center">
                  <div>
                    <p className="fin-title mt-3 mb-1 mt-lg-0 mb-lg-4 bolder">
                      An Alumni network growing in size & influence
                    </p>
                    <p className="fi-txt mb-3 mb-lg-0">
                      700+ Delhi Alumni span a diverse set of puzzle pieces from
                      teaching and teacher training in schools, community-level
                      initiatives, policy, governance and state-level reforms to
                      health, sanitation, nutrition and the private sector.
                      Moreover, as more Alumni are starting to occupy positions
                      of influence - as CEOs, Founders and heads of government
                      bodies, they’re starting to impact 4.4 million Delhi
                      children through various programs and initiatives.
                    </p>
                  </div>
                </div>
              </Row>
              <Row className="py-lg-3 mx-0">
                <div className="col-lg-6">
                  <img src={delSec3} className="w-100" />
                </div>
                <div className="col-lg-6 pl-lg-5 align-center">
                  <div>
                    <p className="fin-title mt-3 mb-1 mt-lg-0 mb-lg-4 bolder">
                      Deepening partnership with the Government
                    </p>
                    <p className="fi-txt mb-3 mb-lg-0">
                      The state government’s focus on spending 26% of its budget
                      on education has been a huge opportunity for the region.
                      The recent involvement of both Teach For India, as well as
                      Teach For India Alumni in projects spanning the model
                      school development, mentor teacher training, democracy
                      curriculum, child protection and more is a proof point of
                      how the Teach For India Delhi community is becoming a
                      partner in changing the face of education in Delhi.
                    </p>
                  </div>
                </div>
              </Row>
            </Container>
            <Container className="py-lg-5 px-3 px-lg-0">
              <Slider {...settingsSoc} className="laf-fel regions">
                <div className="fel-car pt-3 py-lg-5">
                  <p className="fi-head sp-abs">Stories of Impact</p>
                  <div className="fel-cont">
                    <p className="fel-car-title">
                      Alohomora’s partnership with Delhi Government
                    </p>
                    <p className="fel-car-txt">
                      Alohomora, led by Teach For India Alumni, Divakar Sankhla
                      and Parinita Jain, have partnered with the Delhi
                      government to work on Entrepreneurship curriculum for 11th
                      and 12th grade. Additionally, Alohomora and Teach For
                      India have collaborated to get 2,000 kids college-ready.
                    </p>
                  </div>
                  <div className="fel-img-cont">
                    <img src={delSoc1} className="w-100 " />
                  </div>
                </div>
                <div className="fel-car pt-3 py-lg-5">
                  <p className="fi-head sp-abs">Stories of Impact</p>
                  <div className="fel-cont">
                    <p className="fel-car-title">
                      Meraki&apos;s partnership with the Delhi government
                    </p>
                    <p className="fel-car-txt">
                      Meraki, led by Teach For India Alumnus, Seemant Dadwal,
                      has joined hands with the Delhi government to enable
                      parental support for kids education during the Covid-19
                      pandemic.
                    </p>
                  </div>
                  <div className="fel-img-cont">
                    <img src={delSoc2} className="w-100" />
                  </div>
                </div>
              </Slider>
            </Container>
          </Tab.Pane>

          <Tab.Pane eventKey="fifth" className="region hyderabad">
            <div className="bg-sec posrel">
              <img src={regionsDot} className="regPatch" />
              <Container>
                <p className="fi-head text-center">The Journey</p>

                <div className="timeline">
                  <div className="tl-item pb-5">
                    <div className="year">2021</div>
                    <div className="content">
                      <p className="ed-title bolder">
                        87 Fellows
                        <br /> 3600 Students
                        <br /> 422 Alumni
                      </p>
                    </div>
                  </div>
                  <div className="tl-item pb-5">
                    <div className="year">2019</div>
                    <div className="content">
                      <p className="fi-txt">
                        <span className="bolder">
                          Alumni led organisations and Alumni in positions
                        </span>
                        <br />
                        of influence with the government.
                      </p>
                    </div>
                  </div>
                  <div className="tl-item pb-5">
                    <div className="year">2018</div>
                    <div className="content">
                      <p className="fi-txt">
                        <span className="bolder">200+ Student Leaders</span>
                        <br />
                        Hyderabad saw a diverse range of student led initiatives
                        that fundamentally redefined our Alumni Learning belief
                        of what is possible!
                      </p>
                    </div>
                  </div>

                  <div className="tl-item pb-5">
                    <div className="year">2017</div>
                    <div className="content">
                      <p className="fi-txt">
                        <span className="bolder">
                          Alumni Learning -Community Prayas
                        </span>
                        <br />A group of like-minded Alumni came together to
                        conceptualise the first Alumni led learning community -
                        Prayas, an attempt to create a collaborative
                        problem-solving space to bring transformative change in
                        schools.
                      </p>
                    </div>
                  </div>

                  <div className="tl-item pb-5">
                    <div className="year">2014</div>
                    <div className="content">
                      <p className="fi-txt">
                        <span className="bolder">
                          Inception of Fellow Durbar
                        </span>
                        <br />
                        Fellow Durbar is an organic space for people to come
                        together and share. Fellow Durbar is now an integral
                        part of Teach For India’s ecosystem across 7 cities.
                      </p>
                    </div>
                  </div>

                  <div className="tl-item pb-5">
                    <div className="year">2012</div>
                    <div className="content">
                      <p className="fi-txt">
                        <span className="bolder">Hyderabad’s first cohort</span>
                        <br />
                        With 46 Fellows & 1800 Students
                      </p>
                    </div>
                  </div>
                  <div className="tl-item pb-5">
                    <div className="year">2009</div>
                    <div className="content">
                      <p className="fi-txt">
                        <span className="bolder">The Fellowship begins</span>
                        <br />
                        in Mumbai & Pune
                      </p>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
            <Container className="py-lg-5">
              <p className="wn-title mt-0 text-center pb-lg-5 mb-3 mb-lg-0">
                What Enabled Change{" "}
              </p>
              <Row className="py-lg-3 mx-0">
                <div className="col-lg-6">
                  <img src={hydSec1} className="w-100" />
                </div>
                <div className="col-lg-6 pl-lg-5 align-center">
                  <div>
                    <p className="fin-title mt-3 mb-1 mt-lg-0 mb-lg-4 bolder">
                      Invested Government Institutions
                    </p>
                    <p className="fi-txt mb-3 mb-lg-0">
                      The last few years have seen a significant shift in the
                      way government institutions such as the SCERT, Samagra
                      Siksha, Directorate of School Education, and the Telangana
                      Social Welfare Institutions are steering change in the
                      region. There has been an increased emphasis on improving
                      learning outcomes and foundational literacy and numeracy
                      in the region. Government institutions are open to
                      exploring a range of public-private partnerships.
                    </p>
                  </div>
                </div>
              </Row>
              <Row className="py-lg-3 mx-0">
                <div className="col-lg-6">
                  <img src={hydSec2} className="w-100" />
                </div>
                <div className="col-lg-6 pl-lg-5 align-center">
                  <div>
                    <p className="fin-title mt-3 mb-1 mt-lg-0 mb-lg-4 bolder">
                      Alumni Collaboratives
                    </p>
                    <p className="fi-txt mb-3 mb-lg-0">
                      We have witnessed multiple collaboratives come alive since
                      the inception of Teach For India. Be it the diverse range
                      of Institutions like Alokit, Catalyst and 321, started/led
                      by Alumni entrepreneurs, or be it the diverse set of
                      Alumni collaboratives such as Prayas, Bharat Dekho and
                      others conceptualised to mobilise the Alumni collective,
                      we have witnessed multiple initiatives that have led to a
                      meaningful shift in the region.
                    </p>
                  </div>
                </div>
              </Row>
              <Row className="py-lg-3 mx-0">
                <div className="col-lg-6">
                  <img src={hydSec3} className="w-100" />
                </div>
                <div className="col-lg-6 pl-lg-5 align-center">
                  <div>
                    <p className="fin-title mt-3 mb-1 mt-lg-0 mb-lg-4 bolder">
                      Growth of service-based Educational Institutions
                    </p>
                    <p className="fi-txt mb-3 mb-lg-0">
                      The last decade has also witnessed mass migration of
                      Students from state-run government schools to low-income
                      private schools. This led to a huge demand for affordable
                      high quality teaching-learning resources, school
                      leadership training, teacher training, among various other
                      areas. The region has seen organisations like Learning
                      Curve, LEAD, 321 Education, Godrej Foundation, among
                      others, that are working directly with the BPS segment in
                      steering key service delivery to these schools.
                    </p>
                  </div>
                </div>
              </Row>
            </Container>
            <Container className="py-lg-5 px-3 px-lg-0">
              <Slider {...settingsSoc} className="laf-fel regions">
                <div className="fel-car pt-3 py-lg-5">
                  <p className="fi-head sp-abs">Stories of Impact</p>
                  <div className="fel-cont">
                    <p className="fel-car-title">The Freedom Schools</p>
                    <p className="fel-car-txt">
                      TSWREIS, Inqui-Lab Foundation and Teach For India came
                      together to conceptualize the Freedom schools, a chain of
                      innovative democratic government schools set up by TSWREIS
                      under the leadership of Dr. Praveen Kumar, IPS, one of
                      Teach For India’s many regional champions.
                    </p>
                  </div>
                  <div className="fel-img-cont">
                    <img src={hydSoc1} className="w-100 " />
                  </div>
                </div>
                <div className="fel-car pt-3 py-lg-5">
                  <p className="fi-head sp-abs">Stories of Impact</p>
                  <div className="fel-cont">
                    <p className="fel-car-title">
                      Steering change in policy and governance
                    </p>
                    <p className="fel-car-txt">
                      J-PAL, Haqdarshak and Indus Action have played a pivotal
                      role in steering key changes in policy and governance in
                      the state of Telangana. All three organisations have our
                      Alumni serving in positions of influence.
                    </p>
                  </div>
                  <div className="fel-img-cont">
                    <img src={hydSoc2} className="w-100" />
                  </div>
                </div>
              </Slider>
            </Container>
          </Tab.Pane>
          <Tab.Pane eventKey="sixth" className="region mumbai">
            <div className="bg-sec posrel">
              <img src={regionsDot} className="regPatch" />
              <Container>
                <p className="fi-head text-center">The Journey</p>

                <div className="timeline">
                  <div className="tl-item pb-5">
                    <div className="year">2021</div>
                    <div className="content">
                      <p className="ed-title bolder">
                        144 Fellows
                        <br /> 4000 Students
                        <br /> 817 Alumni
                      </p>
                    </div>
                  </div>

                  <div className="tl-item pb-5">
                    <div className="year">2020</div>
                    <div className="content">
                      <p className="fi-txt">
                        <span className="bolder">
                          Teach For India Mumbai’s growing collaboration with
                          MCGM
                        </span>
                        <br />
                        Teach For India&apos;s partnership with the government
                        has evolved over the years from being mere service
                        providers to them reaching out to us for advice and
                        collaborating on projects like training MCGM staff,
                        Student Advisory council, best practice sharing.
                      </p>
                    </div>
                  </div>
                  <div className="tl-item pb-5">
                    <div className="year">2019</div>
                    <div className="content">
                      <p className="fi-txt">
                        <span className="bolder">Bridging the reading gap</span>
                        <br />
                        60% of our Grade 4 Students in Mumbai will bridge the
                        reading gap to grade by the end of this academic year.
                        According to the longitudinal study in partnership with
                        Columbia University, classrooms in Mumbai with Teach For
                        India intervention showed 3-4X growth as compared to
                        non-Teach For India classrooms.
                      </p>
                    </div>
                  </div>

                  <div className="tl-item pb-5">
                    <div className="year">2017</div>
                    <div className="content">
                      <p className="fi-txt">
                        <span className="bolder">
                          Teach For India Mumbai’s first batch of Students
                          graduate from 10th Grade
                        </span>
                        <br />
                        Our Grade 10 SSC pass % was 93% in 2016-17, 94% in
                        2017-18 and 23% higher than the MCGM average in 2018-19
                        and 96% in 2019-20. Over the next four years, nearly
                        3000 Students will have graduated from Teach For India
                        Mumbai and a significant percentage will be enrolled in
                        colleges across the city and elsewhere.
                      </p>
                    </div>
                  </div>

                  <div className="tl-item pb-5">
                    <div className="year">2015</div>
                    <div className="content">
                      <p className="fi-txt">
                        <span className="bolder">Building Alumni Movement</span>
                        <br />
                        The city has been steadily growing its Alumni network
                        from 43 Alumni in 2011 to 280 in 2015. 321, Mumbai’s
                        first Alumni led organization is already establishing
                        its impact. For the first time, we have about 80 odd
                        Alumni in one room talking about Teach For India’s phase
                        3.
                      </p>
                    </div>
                  </div>

                  <div className="tl-item pb-5">
                    <div className="year">2011</div>
                    <div className="content">
                      <p className="fi-txt">
                        <span className="bolder">Mumbai’s first cohort</span>
                        <br />
                        With 46 Fellows & 1122 Students
                      </p>
                    </div>
                  </div>
                  <div className="tl-item pb-5">
                    <div className="year">2009</div>
                    <div className="content">
                      <p className="fi-txt">
                        <span className="bolder">The Fellowship begins</span>
                        <br />
                        in Mumbai & Pune 87 Fellows
                      </p>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
            <Container className="py-lg-5">
              <p className="wn-title mt-0 text-center pb-lg-5 mb-3 mb-lg-0">
                What Enabled Change{" "}
              </p>
              <Row className="py-lg-3 mx-0">
                <div className="col-lg-6">
                  <img src={mumSec1} className="w-100" />
                </div>
                <div className="col-lg-6 pl-lg-5 align-center">
                  <div>
                    <p className="fin-title mt-3 mb-1 mt-lg-0 mb-lg-4 bolder">
                      Alumni strengthening the school ecosystem
                    </p>
                    <p className="fi-txt mb-3 mb-lg-0">
                      Students in Mumbai classrooms are growing in their values,
                      exposure and awareness with the presence of Alumni-led
                      opportunities like Right Pitch, Just For Kicks, Saturday
                      Art Class, Claylabs, OnTrack, Udaan, Ummeed and others.
                      Whether it’s extra-curricular activities, inclusive
                      support, career guidance or value-based education, our
                      Alumni are present in every level of the schooling system
                      and are filling the academic and holistic growth gap.
                    </p>
                  </div>
                </div>
              </Row>
              <Row className="py-lg-3 mx-0">
                <div className="col-lg-6">
                  <img src={mumSec2} className="w-100" />
                </div>
                <div className="col-lg-6 pl-lg-5 align-center">
                  <div>
                    <p className="fin-title mt-3 mb-1 mt-lg-0 mb-lg-4 bolder">
                      Fellows rooted in the needs of children
                    </p>
                    <p className="fi-txt mb-3 mb-lg-0">
                      Our Fellows know what it means to set their Students on a
                      transformational and holistic life path and have been
                      recognized by CENTA TPO for their outstanding contribution
                      to their classrooms. Some choose to continue teaching for
                      2-4 years beyond their Fellowship. Our Fellows have
                      expanded their impact beyond their classrooms towards
                      transforming outcomes for their schools through projects,
                      persisting through challenges and creating proof points of
                      what is possible in our highest needs classrooms and
                      communities.
                    </p>
                  </div>
                </div>
              </Row>
              <Row className="py-lg-3 mx-0">
                <div className="col-lg-6">
                  <img src={mumSec3} className="w-100" />
                </div>
                <div className="col-lg-6 pl-lg-5 align-center">
                  <div>
                    <p className="fin-title mt-3 mb-1 mt-lg-0 mb-lg-4 bolder">
                      A rich ecosystem of organizations
                    </p>
                    <p className="fi-txt mb-3 mb-lg-0">
                      Mumbai has a great ecosystem of organizations operating in
                      different parts of the education sector. Many Alumni have
                      joined these organizations in various roles. For example,
                      Aseema, Akanksha and Muktangan have created proof points
                      of an excellent schooling system. Ummeed, Seeds of
                      Awareness, Sols Arc and Jai Vakeel strive for inclusive
                      and remedial education. Praja, Dasra, Project Mumbai and
                      Haqdarshak are examples of organizations working as a
                      bridge between government and citizens.
                    </p>
                  </div>
                </div>
              </Row>
            </Container>
            <Container className="py-lg-5 px-3 px-lg-0">
              <Slider {...settingsSoc} className="laf-fel regions">
                <div className="fel-car pt-3 py-lg-5">
                  <p className="fi-head sp-abs">Stories of Impact</p>
                  <div className="fel-cont">
                    <p className="fel-car-title">Transforming lives</p>
                    <p className="fel-car-txt">
                      Subhankar and his Student Arshad both had their lives
                      transformed by the Fellowship. Arshad successfully
                      graduated 10th grade from a Teach For India classroom, and
                      is now studying in a top-tier college while interning with
                      Lido Learning. Subhankar went onto start his own
                      organization after his Fellowship. He now trains teachers
                      in low-income private schools. In Arshad’s words,
                      “Shubhankar Bhaiya taught me what it means to be a good
                      human being, in class, on the field and in life. That is
                      who I want to be.”
                    </p>
                  </div>
                  <div className="fel-img-cont">
                    <img src={mumSoc1} className="w-100 " />
                  </div>
                </div>
                <div className="fel-car pt-3 py-lg-5">
                  <p className="fi-head sp-abs">Stories of Impact</p>
                  <div className="fel-cont">
                    <p className="fel-car-title">
                      Creating spaces for mental health
                    </p>
                    <p className="fel-car-txt">
                      The Mind Room was created by Anju Joseph, Shraddha Ghadi,
                      Sajida Munaf, Sumona Shetty, Dipti Balwani and Romana
                      Shaikh with the support of Elgiva Kharsati and Pooja Pawar
                      from the Mumbai Alumni Impact team. They organise
                      workshops and hold spaces to foster sustained mental
                      health support for members of our community.
                    </p>
                  </div>
                  <div className="fel-img-cont">
                    <img src={mumSoc2} className="w-100" />
                  </div>
                </div>
              </Slider>
            </Container>
          </Tab.Pane>
          <Tab.Pane eventKey="seventh" className="region pune">
            <div className="bg-sec posrel">
              <img src={regionsDot} className="regPatch" />
              <Container>
                <p className="fi-head text-center">The Journey</p>

                <div className="timeline">
                  <div className="tl-item pb-5">
                    <div className="year">2021</div>
                    <div className="content">
                      <p className="ed-title bolder">
                        145 Fellows
                        <br /> 7500 Students
                        <br /> 854 Alumni
                      </p>
                    </div>
                  </div>
                  <div className="tl-item pb-5">
                    <div className="year">2020</div>
                    <div className="content">
                      <p className="fi-txt">
                        <span className="bolder">
                          10 Year Partnership with low-income private schools
                        </span>
                        <br />
                        Completed 10 years of working with low-income private
                        schools in Pune while providing excellent education to
                        Students across grades
                      </p>
                    </div>
                  </div>
                  <div className="tl-item pb-5">
                    <div className="year">2019</div>
                    <div className="content">
                      <p className="fi-txt">
                        <span className="bolder">First Board Results </span>
                        <br />
                        Our first batch of Grade 10 Students cleared the SSC
                        exams with 98% Students passing.
                      </p>
                    </div>
                  </div>

                  <div className="tl-item pb-5">
                    <div className="year">2018</div>
                    <div className="content">
                      <p className="fi-txt">
                        <span className="bolder">
                          Access to Secondary Education
                        </span>
                        <br />
                        Access to English-medium secondary classrooms, a huge
                        issue for Pune’s kids, addressed through the PPP model
                        partnerships with Aakanksha Foundation and iTeach
                        Schools.
                      </p>
                    </div>
                  </div>

                  <div className="tl-item pb-5">
                    <div className="year">2014</div>
                    <div className="content">
                      <p className="fi-txt">
                        <span className="bolder">Community Engagement</span>
                        <br />
                        Series of Community Centres started across clusters to
                        address the academic gap, programs for parents and
                        extra-curricular activities.
                      </p>
                    </div>
                  </div>

                  <div className="tl-item pb-5">
                    <div className="year">2011</div>
                    <div className="content">
                      <p className="fi-txt">
                        <span className="bolder">Pune’s first cohort</span>
                        <br />
                        With 32 Fellows & 1100 Students
                      </p>
                    </div>
                  </div>
                  <div className="tl-item pb-5">
                    <div className="year">2009</div>
                    <div className="content">
                      <p className="fi-txt">
                        <span className="bolder">The Fellowship begins</span>
                        <br />
                        in Mumbai & Pune 87 Fellows
                      </p>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
            <Container className="py-lg-5">
              <p className="wn-title mt-0 text-center pb-lg-5 mb-3 mb-lg-0">
                What Enabled Change{" "}
              </p>
              <Row className="py-lg-3 mx-0">
                <div className="col-lg-6">
                  <img src={punSec1} className="w-100" />
                </div>
                <div className="col-lg-6 pl-lg-5 align-center">
                  <div>
                    <p className="fin-title mt-3 mb-1 mt-lg-0 mb-lg-4 bolder">
                      A growing network of Alumni in the region
                    </p>
                    <p className="fi-txt mb-3 mb-lg-0">
                      81% of the Alumni in the region continue to work in the
                      education sector. From teacher training to child safety,
                      technology to school leadership, our Alumni in the region
                      continue to impact more than 1 million Students, 40,000
                      teachers and 800 government officials. Many Alumni led
                      organizations have also created jobs for our graduating
                      cohorts across various projects and roles.
                    </p>
                  </div>
                </div>
              </Row>
              <Row className="py-lg-3 mx-0">
                <div className="col-lg-6">
                  <img src={punSec2} className="w-100" />
                </div>
                <div className="col-lg-6 pl-lg-5 align-center">
                  <div>
                    <p className="fin-title mt-3 mb-1 mt-lg-0 mb-lg-4 bolder">
                      Culture of Innovation and Problem Solving
                    </p>
                    <p className="fi-txt mb-3 mb-lg-0">
                      Over the years, the Pune team has fostered a culture of
                      problem solving and innovation amongst the Fellows. The
                      city has seen legacy projects run completely by Fellows -
                      like Show Us The Talent (Shoutt) and Model United Nations
                      (Teach For India MUN) which continue to grow in size and
                      impact. A large number of BTCPs have established
                      themselves as long-standing projects and some, eventually
                      organisations, like Life Lab, Pijam , TAP providing
                      crucial value added services to our schools.
                    </p>
                  </div>
                </div>
              </Row>
              <Row className="py-lg-3 mx-0">
                <div className="col-lg-6">
                  <img src={punSec3} className="w-100" />
                </div>
                <div className="col-lg-6 pl-lg-5 align-center">
                  <div>
                    <p className="fin-title mt-3 mb-1 mt-lg-0 mb-lg-4 bolder">
                      Strong Network of Advocates
                    </p>
                    <p className="fi-txt mb-3 mb-lg-0">
                      Since 2014 the Pune Advisory Board has, through their
                      relationships and influence, enabled us to form long
                      lasting financial partnerships in the city, in addition to
                      guiding our strategic work. The Pune Alumni Chapter, which
                      comprises Alumni across organizations, helps create spaces
                      that enable collaboration within the Alumni community,
                      thereby catalysing the work in the region.
                    </p>
                  </div>
                </div>
              </Row>
            </Container>
            <Container className="py-lg-5 px-3 px-lg-0">
              <Slider {...settingsSoc} className="laf-fel regions">
                <div className="fel-car pt-3 py-lg-5">
                  <p className="fi-head sp-abs">Stories of Impact</p>
                  <div className="fel-cont">
                    <p className="fel-car-title">
                      Changing system level policies
                    </p>
                    <p className="fel-car-txt">
                      A powerful network of schools: iTeach, Akanksha and Avsara
                      Schools have allowed for our Students to access excellent
                      education and fill a major gap in the city. 13 of these
                      Pune schools are led by Teach For India Alumni and have
                      played a pivotal role in steering key changes in system
                      level policies in the state of Maharashtra.
                    </p>
                  </div>
                  <div className="fel-img-cont">
                    <img src={punSoc1} className="w-100 " />
                  </div>
                </div>
                <div className="fel-car pt-3 py-lg-5">
                  <p className="fi-head sp-abs">Stories of Impact</p>
                  <div className="fel-cont">
                    <p className="fel-car-title">Enabling student leadership</p>
                    <p className="fel-car-txt">
                      Over the last few years, through multiple efforts of
                      developing student leadership in Pune, we have been able
                      to develop a partnership between students and educators by
                      creating safe spaces and empowering them towards a path of
                      extended opportunities like being a KER Revolutionary,
                      taking part in &apos;Maya&apos; and &apos;The Greatest
                      Show on Earth&apos;.
                    </p>
                  </div>
                  <div className="fel-img-cont">
                    <img src={punSoc2} className="w-100" />
                  </div>
                </div>
                <div className="fel-car pt-3 py-lg-5">
                  <p className="fi-head sp-abs">Stories of Impact</p>
                  <div className="fel-cont">
                    <p className="fel-car-title">Setting an example</p>
                    <p className="fel-car-txt">
                      In the region, InnovatED and TFIx have enabled Alumni led
                      organizations - Pune Children’s Zone, The Apprentice
                      Project and PiJam Foundation to impact over 3000 children
                      and over 100 families.
                    </p>
                  </div>
                  <div className="fel-img-cont">
                    <img src={punSoc3} className="w-100 " />
                  </div>
                </div>
              </Slider>
            </Container>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Layout>
  )
}

export default Regions
